import holiday from "./holiday";
import notice from "./notice";
import room from "./room";
import place from "./place";
import assTemp from "./assTemp";
import assProgram from "./assProgram";
import master from "./buddha/master";
import tk from "./buddha/tk";
import timeRange from "./timeRange";
import palace from "./palace";
import car from "./car";
import box from "./box";
import buddhaAnnounce from "./buddhaAnnounce";
import kt from "./kt";
import banner from "./culture/banner";
import article from "./culture/article";
import blessPalace from "./culture/palace";
import cultureNotice from "./culture/notice";
import introduce from "./culture/introduce";
import guide from "./culture/guide";
import plan from "./plan";
import trainQuestion from "./volunteer/trainQuestion";
import opeSouvenir from "./volunteer/opeSouvenir";
import culType from "./volunteer/culType";
import shareArticle from './volunteer/shareArticle';
import goods from "./light/goods";
import lesson from "./study/lesson";
import lessonType from "./study/lessonType";
import contract from "./program/contract";

export default {
    holiday,
    notice,
    room,
    place,
    assTemp,
    assProgram,
    master,
    tk,
    car,
    box,
    timeRange,
    palace,
    announce: buddhaAnnounce,
    kt,
    banner,
    article,
    blessPalace,
    cultureNotice,
    introduce,
    guide,
    plan,
    trainQuestion,
    opeSouvenir,
    culType,
    shareArticle,
    goods,
    lesson,
    lessonType,
    contract
}
