import { getKeyTitle } from "../../js/tool";
import config from "../../js/config";

export const guideTypeList = [
    {key: 1, title: '殿宇'},
    {key: 2, title: '佛像'},
    {key: 3, title: '壁画'},
    {key: 4, title: '经幢'},
]

export const articleSourceTypeList =[
    {key: 1, title: '跳转链接'},
    {key: 2, title: '文章发布'},
]

export const getGuideTypeText = function (type) {
    return getKeyTitle(guideTypeList, type);
}

export const guideColumns = [
    {title: '导览名称', dataIndex: 'name'},
    {title: '分类', dataIndex: 'type', customRender: text => getGuideTypeText(text) || '--'},
    {title: '创建时间', dataIndex: 'create_time'},
]

export default {
    name: '导览',
    url: '/admin/mini-guide', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [...guideColumns]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'type',
            label: '类型',
            component: 'a-select',
            props: {
                placeholder: '选择分类筛选',
                allowClear: true,
                options: [
                    ...guideTypeList
                ]
            },
            style: {
                width: "200px"
            }
        },
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入导览名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '导览名称',
            component: 'a-input',
            props: {
                placeholder: '请输入导览名称',
            },
            rules: [{ required: true, message: '请输入导览名称', trigger: 'blur' }]
        },
        {
            key: 'type',
            label: '导览分类',
            component: 'a-select',
            props: {
                placeholder: '请选择导览分类',
                options: [...guideTypeList],
            },
            style: {
                width: "100%"
            },
            rules: [{ required: true, message: '请选择导览分类', trigger: 'change' }]
        },
        {
            key: 'title',
            label: '关联标题',
            component: 'a-input',
            props: {
                placeholder: '请输入关联标题',
            },
        },
        {
            key: 'cover_img',
            label: '导览封面',
            component: 'form-upload',
            props: {
                limit: 1,
                uploadTip: '支持png、jpg格式，建议图片尺寸750*1624，最多上传1张，大小限制2M'
            },
            rules: [{ required: true, message: '请上传导览封面', trigger: 'change' }]
        },
        {
            key: 'img',
            label: '导览图片',
            component: 'form-upload',
            props: {
                limit: 9,
                uploadTip: '支持png、jpg格式，建议图片比例9:16，竖版；最多上传9张，首张图片为封面图，每张图片小于2M'
            },
            rules: [{ required: true, message: '请上传导览图片', trigger: 'change' }]
        },
        {
            key: 'video',
            label: '导览视频',
            component: 'form-upload',
            props: {
                limit: 1,
                mediaType: "video",
                uploadTip: '支持MP4格式，建议视频比例16:9，仅限上传一个视频，视频大小不超过500M'
            },
        },
        {
            key: 'video_img',
            label: '视频封面',
            component: 'form-upload',
            props: {
                limit: 1,
                uploadTip: '支持png、jpg格式，建议图片比例16:9，大小限制2M'
            },
        },
        {
            key: 'voice',
            label: '语音讲解',
            component: 'form-upload',
            props: {
                limit: 1,
                mediaType: "audio",
                uploadTip: '支持MP3格式，仅限上传一个音频，音频大小不超过100M'
            },
        },
        {
            key: 'info',
            label: '文字介绍',
            component: 'rich-editor',
            rules: [{ required: true, message: '请输入导览文字介绍', trigger: 'blur' }]
        }
    ],
    actionWidth: 240,
    actions: [
        {
            title: '二维码下载',
            listeners: {
                click(e, item) {
                    this.$axios({
                        url: '/mini/common/share-code',
                        method: 'POST',
                        data: {
                            path: `pages/tour/tour?id=${item.id}`
                        }
                    }).then(res => {
                        const url = `${config.host}/${res.data}`;
                        window.open(url, "_blank");
                    })
                }
            }
        }
    ],
    // 详情接口expand字段
    expand: '',
    init: {
        source: 2
    }, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: (ctx) => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement;
        const items = [
            {
                label: '导览名称',
                key: 'name',
            },
            {
                label: '分类',
                slot: entity => <span>{getGuideTypeText(entity.type)}</span>
            },
            {
                label: '关联标题',
                key: 'title',
            },
            {
                label: '导览封面',
                key: 'cover_img',
                img: true
            },
            {
                label: '导览图片',
                key: 'img',
                img: true
            },
            {
                label: '导览视频',
                key: 'videoList',
                media: true
            },
            {
                label: '语音讲解',
                key: 'audioList',
                media: true
            },
            {
                label: '文字介绍',
                key: 'info',
                rich: true
            }
        ];
        return items;
    },
    // 编辑实体前处理函数
    dealDetail: function(detail) {
        return new Promise(resolve => {
            if(detail.video) {
                detail.videoList = [{
                    type: 'video',
                    poster: detail.video_img,
                    url: detail.video
                }]
            }
            if(detail.voice) {
                detail.audioList = [{
                    type: 'audio',
                    url: detail.voice
                }]
            }
            resolve();
        })
    },
    component: 'GuideOther',
}
